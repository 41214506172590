jQuery(function($) {
  var $smooth  = $('a[href^="#"]');
  // #で始まるアンカーをクリックした場合に処理
  $smooth.on('click', function() {
    // スクロールの速度
    var speed = 800;// ミリ秒
    // アンカーの値取得
    var href= $(this).attr("href");
    // 移動先を取得
    var target = $(href === '#top' || href === '#' || href === '' ? 'html' : href);
    // var nav = $("#nav").height();
    var header =  $('#header .inner').outerHeight();
    // 移動先を数値で取得
    var position = target.offset().top - header;
    // スムーススクロール
     if( !$('body html').is(':animated') ) {
        $('body,html').stop().animate({scrollTop:position}, speed, 'swing');
    }
    return false;
  });

  $('.q-faq').click(function(){
    $(this).parent('.ar-faq').children('.a-faq').slideToggle();
    if($(this).parent('.ar-faq').hasClass('active')){
      $(this).parent('.ar-faq').removeClass('active');
      $(this).children('span').find('img').attr('src','img/faq_arrow_off.png');
    }else{
      $(this).parent('.ar-faq').addClass('active');
      $(this).children('span').find('img').attr('src','img/faq_arrow_on.png');
    }
  });

  $(window).load(function(){
    var height = $('.fixed-bottom').height();
    $('.copyright').css('margin-bottom',height);
  });
});